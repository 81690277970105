import putAxios from "services/axios-put";
import getAxios from "services/axios-get";
import deleteAxios from "services/axios-delete";

import AddNewCarDialog from "@/components/AddNewCarDialog";
import EditNewCarDialog from "@/components/EditNewCarDialog";

export default {
  components: {
    AddNewCarDialog,
    EditNewCarDialog,
  },
  data() {
    return {
      carBrandFilter: null,
      carBrandEntries: [],
      carBrandSearch: null,
      snackMessage: "",
      snackbar: false,
      color: "primary",
      coeDateModal: false,
      regDateModal: false,
      renewDateModal: false,
      statusFilter: "",
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      defaultImgPath: null,
      imgUrl: null,
      pageSizeList: [10, 20, 50, 100],
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      editNewCarStatus: {
        carId: "",
        status: "",
      },
      delNewCar: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      editStatusDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Name", value: "productTitle" },
        { text: "Car Brand | Model | Submodel", value: "carModel" },
        { text: "Price", value: "price" },
        { text: "Images", value: "displayImgs" },
        { text: "Dealer", value: "dealerName" },
        { text: "Status", value: "status" },
        { text: "Position", value: "position" },
        { text: "Variant Position", value: "variantPosition" },
        { text: "Edit" },
        { text: "Delete" },
        { text: "Id", value: "id" },
      ],
      newCar: [],
      descriptionLimit: 60,
      isLoading: false,
      coeTypeList: [],
      idTypeList: [],
      vesCategoryList: [],
      coeCatList: [],
      carStatusList: [
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "INACTIVE", value: "INACTIVE" },
        { text: "SOLD", value: "SOLD" },
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "REJECTED", value: "REJECTED" },
      ],
      dealerNameSearch: "",
    };
  },
  mounted() {
    this.fetchNewCars();
    this.fetchVesCat();
    this.fetchCOECat();
    this.fetchCOEType();
  },
  watch: {
    carBrandSearch(val) {
      if (val.length <= 2) return;
      if (this.isLoading) return;
      this.isLoading = true;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/carBrand?searchContent=${val}`
      )
        .then(res => {
          const { count, data } = res;
          this.count = count;
          this.carBrandEntries = data.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  methods: {
    error() {
      console.log("failed");
    },
    delButtonClick(item) {
      this.delNewCar.id = item.id;
      this.delNewCar.name = item.productTitle;
      this.deleteDialog = true;
    },
    editStatusButtonClick(item) {
      this.editNewCarStatus.carId = item.id;
      this.editNewCarStatus.status = item.status;
      this.editStatusDialog = true;
    },
    doDelNewCar() {
      const self = this;
      const param = {
        carId: self.delNewCar.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/delCarNew`,
        param,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchNewCars();
          self.$message({
            message: "Car Deleted Successfully!",
            type: "success",
          });
        }
      });
    },
    async doEditStatusNewCar() {
      const self = this;

      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/updCarStatus?carId=` +
            self.editNewCarStatus.carId +
            "&status=" +
            self.editNewCarStatus.status,
          self.editNewCarStatus,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.editStatusDialog = false;
            self.fetchNewCars();
            self.showSnack("Edit Car Status Successfully", "success");
          }
        });
      } catch (e) {
        console.error(e);
        self.showSnack("Edit Car Status Failed" + e, "error");
      }
    },
    chooseImage() {
      this.addNewCar.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    searchType() {
      this.fetchNewCars();
    },
    handlePageChange() {
      this.fetchNewCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchNewCars();
    },
    fetchVesCat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/vesCategory`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.vesCategoryList = res.data.data;
        }
      });
    },
    fetchCOECat() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeCategoryPair`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeCatList = res.data.data.content;
        }
      });
    },
    fetchCOEType() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/generic/categoryType`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.coeTypeList = res.data.data;
        }
      });
    },
    fetchNewCars() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        productTitle: self.nameSearch,
        carStatus: self.statusFilter,
        name: self.dealerNameSearch,
        uEmail: self.dealerNameSearch,
        carBrandId: self.carBrandFilter ? self.carBrandFilter.id : null,
        sort: "car.createTime,desc",
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carNew?isAdDealer=true&sort=car.sortCode,asc`,
        params,
        self.token
      ).then(res => {
        if (res.data) {
          self.newCar = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    showSnack(msg, color) {
      this.snackMessage = msg;
      this.color = color;
      this.snackbar = true;
    },
    add() {
      this.fetchNewCars();
      this.showSnack("Add Car Successfully", "success");
    },
    edit() {
      this.fetchNewCars();
      this.showSnack("Edit Car Successfully", "success");
    },
  },
};
