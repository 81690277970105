import getAxios from "services/axios-get";
import postAxios from "services/axios-post";
import createWatermark from "../../../services/create-watermark";
import uploadSignedImage from "../../../services/aws-upload-image";

import ImgGroupInput from "@/components/ImgGroupInput";
import SalespersonGroupInput from "@/components/SalespersonGroupInput";

export default {
  components: {
    ImgGroupInput,
    SalespersonGroupInput,
  },
  props: {
    isAd: Boolean,
    coeTypeList: {
      type: Array,
    },
    coeCatList: {
      type: Array,
    },
    vesCategoryList: {
      type: Array,
    },
  },
  data() {
    return {
      addNewCar: {
        arfPrice: 0,
        carModelCode: "",
        coeCategoryId: "",
        coePrice: 0,
        coeType: "",
        color: "",
        defaultImgPath: "",
        displayImgs: [],
        draft: false,
        efficiency: 0,
        isDraft: false,
        notes: "",
        seoMetaTitle: "",
        seoMetaDescription: "",
        omvPrice: 0,
        priceHiddenFlag: 0,
        productTitle: "",
        roadTax: 0,
        salePrice: 0,
        salepersonIds: [],
        vesCategoryId: "",
        videoPaths: [],
        manuYear: 0,
        sortCode: null,
        isCoeInclusive: null,
        pricePackage: null,
      },
      loadingUpd: false,
      show: false,
      searchDealer: null,
      dealerJSON: null,
      dealerEntries: [],
      displayImgsFiles: [],
      displayVideoFiles: [],
      assignedSalespersonList: [],
      displayImgsPlaceholder: null,
      radioWatermark: "none",

      carModelJSON: null,
      entries: [],
      isLoading: false,
      search: null,

      carBrandNameDisplay: "",
      carBrandJSON: null,
      carBrandEntries: [],
      carBrandIsLoading: false,
      carBrandSearch: null,

      carModelNameDisplay: "",
      carModelNameJSON: null,
      carModelNameEntries: [],

      carModelSubNameDisplay: "",
      carModelSubNameJSON: null,
      carModelSubNameEntries: [],

      carModelFinalDisplay: "",
      carModelFinalJSON: null,
      carModelFinalEntries: [],
    };
  },
  watch: {
    // Items have already been loaded
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/carModelPair?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.entries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchDealer(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer`,
        {
          isAuthorized: this.isAd,
          searchContent: val,
          status: "APPROVED",
        },
        self.token
      )
        .then(res => {
          if (res.data) {
            self.dealerEntries = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    carBrandSearch(val) {
      if (val == "") {
        this.resetCarModelSelection();
      }
      if (this.carBrandIsLoading) return;

      this.carBrandIsLoading = true;

      // Lazily load input items
      fetch(
        `https://hades.ucars.sg/relatedContent/carBrand?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.carBrandEntries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.carBrandIsLoading = false));
    },
  },
  methods: {
    handleCarModelSelect(carModel) {
      let self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeVesPair?carModelCode=${carModel.code}`
      )
        .then(res => res.json())
        .then(res => {
          let { name: vesCatName, value: coeCatName } = res.data;

          if (vesCatName)
            self.addNewCar.vesCategoryId = self.vesCategoryList.find(
              v => v.name === vesCatName
            ).id;

          if (coeCatName)
            self.addNewCar.coeCategoryId = self.coeCatList.find(
              c => c.name === coeCatName
            ).id;
        })
        .catch(err => console.error(err));
    },
    carBrandChange(carBrand) {
      const carBrandId = carBrand.id;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelModelName?carBrandId=${carBrandId}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelNameEntries = res.data.data;
        }
      });
    },
    carModelNameChange(carBrand, carModelName) {
      const carBrandId = carBrand.id;
      let queryParams = `carBrandId=${carBrandId}&modelName=${encodeURIComponent(
        carModelName
      )}`;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelSubModelName?${queryParams}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelSubNameEntries = res.data.data;
        }
      });
    },

    carModelSubNameChange(carBrand, carModelName, carModelSubName) {
      const carBrandId = carBrand.id;
      let queryParams = `carBrandId=${carBrandId}&modelName=${encodeURIComponent(
        carModelName
      )}&submodelName=${encodeURIComponent(carModelSubName)}`;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getCarModel?${queryParams}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelFinalEntries = res.data.data;
        }
      });
    },

    carModelFinalChange(carModelCodeSelected) {
      this.addNewCar.carModelCode = carModelCodeSelected;
    },
    resetCarModelSelection() {
      this.addNewCar.carModelCode = null;
      this.carModelNameJSON = "";
      this.carModelSubNameJSON = "";
      this.carModelFinalJSON = "";
      this.carModelNameEntries = [];
      this.carModelSubNameEntries = [];
      this.carModelFinalEntries = [];
    },
    async doAddNewCar() {
      const self = this;
      self.loadingUpd = true;
      try {
        self.addNewCar.salepersonIds = self.assignedSalespersonList.map(
          sp => sp.id
        );
        self.addNewCar.priceHiddenFlag = self.addNewCar.priceHiddenFlag ? 1 : 0;

        const isObscure = this.radioWatermark == "watermark" ? true : false;
        const imgResLists = self.$refs.imgs.imgs;
        let img;
        for (let i = 0; i < imgResLists.length; i++) {
          img = imgResLists[i];
          if (img) {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: isObscure,
              },
              this.token
            );
            if (isObscure) {
              const watermarkedImage = await createWatermark(img.file);
              if (watermarkedImage) {
                await uploadSignedImage(
                  watermarkedImage,
                  uploadUrl.data.waterMarkedUrl
                );
                self.addNewCar.displayImgs.push(uploadUrl.data.waterMarkedName);
              }
            } else {
              await uploadSignedImage(img.file, uploadUrl.data.originalUrl);
              self.addNewCar.displayImgs.push(uploadUrl.data.originalName);
            }
          }
        }
        self.addNewCar.defaultImgPath = self.addNewCar.displayImgs.shift();
        // const imgResList = [...(await self.$refs.imgs.upload(isObscure))];

        // self.addNewCar.defaultImgPath = imgResList.shift();
        // self.addNewCar.displayImgs = imgResList;

        // console.log("new car: " + JSON.stringify(self.addNewCar));
        self.addNewCar.priceHiddenFlag = 0;
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/addCarNewForDealer?dealerId=` +
            self.dealerJSON.id,
          self.addNewCar,
          self.token
        )
          .then(res => {
            if (res.status == 201) {
              self.$emit("error", res.data.message);
              return;
            }
            if (res.data) {
              self.reset();
              self.show = false;
              self.$emit("add");
              self.loadingUpd = false;
            }
          })
          .catch(err => {
            self.$emit("error", err);
            console.error(err);
            self.loadingUpd = false;
          })
          .finally(() => (this.loadingUpd = false));
        self.loadingUpd = false;
      } catch (e) {
        self.$emit("error", e);
        self.loadingUpd = false;
        console.error(e);
      }
    },
    reset() {
      this.addNewCar = {
        arfPrice: 0,
        carModelCode: "",
        coeCategoryId: "",
        coePrice: 0,
        coeType: "",
        color: "",
        defaultImgPath: "",
        displayImgs: [],
        draft: false,
        efficiency: 0,
        isDraft: false,
        notes: "",
        seoMetaTitle: "",
        seoMetaDescription: "",
        omvPrice: 0,
        priceHiddenFlag: 0,
        productTitle: "",
        roadTax: 0,
        salePrice: 0,
        salepersonIds: [],
        vesCategoryId: "",
        videoPaths: [],
        manuYear: 0,
        sortCode: null,
      };
      this.dealerJSON = null;
      this.carModelJSON = null;
      this.resetCarModelSelection();
    },
  },
};
