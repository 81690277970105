<template>
  <div>
    <div class="d-flex align-center">
      <h3>Add Salesperson</h3>
      <v-btn class="mx-2" fab small @click="addSalespersonRow">
        <v-icon color="indigo">mdi-plus</v-icon>
      </v-btn>
    </div>
    <div
      class="d-flex align-center"
      v-for="(sp, index) in assignedSalespersonList"
      :key="sp.id"
    >
      <v-select
        :items="remainingSalespersonList"
        return-object
        :label="sp.text"
        @change="assignSalesperson($event, index)"
        no-data-text="No salesperson remaining.."
      ></v-select>
      <v-btn class="mx-2" fab small @click="removeSalespersonRow(sp)">
        <v-icon color="pink">mdi-minus</v-icon>
      </v-btn>
    </div>
    <v-snackbar v-model="snackbarOpen">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbarOpen = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import getAxios from "../../../services/axios-get";

export default {
  props: {
    dealerId: String,
    carId: String,
  },
  data() {
    return {
      salespersonList: [],
      assignedSalespersonList: [],
      remainingSalespersonList: [],
      snackbarOpen: false,
      snackbarText: "",
    };
  },
  computed: {},
  mounted() {
    this.fetchSalespersonList(this.dealerId);
    if (this.carId) {
      this.fetchCarSalespersonList(this.carId);
    } else {
      this.assignedSalespersonList = [];
    }
  },
  watch: {
    carId(newCarId) {
      this.fetchCarSalespersonList(newCarId);
    },
    dealerId(newDealerId) {
      this.assignedSalespersonList = [];
      this.fetchSalespersonList(newDealerId);
    },
    salespersonList(newSalespersonList) {
      this.remainingSalespersonList = newSalespersonList.map(sp => {
        sp.text = `${sp.name}\t${sp.phone}`;
        return sp;
      });
    },
    assignedSalespersonList: {
      deep: true,
      handler(newAssignedSalespersonList) {
        this.remainingSalespersonList = this.salespersonList
          .filter(sp => {
            return (
              newAssignedSalespersonList.filter(asp => asp.id === sp.id)
                .length === 0
            );
          })
          .map(sp => {
            sp.text = `${sp.name}\t${sp.phone}`;
            return sp;
          });
        this.$emit("change", [...newAssignedSalespersonList]);
      },
    },
  },
  methods: {
    fetchCarSalespersonList(carId) {
      const self = this;
      this.assignedSalespersonList = [];
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carInfo/carSaleperson?carId=${carId}`,
        {
          // params: { carId },
        }
      )
        .then(res => {
          const dataRes = res.data.data;
          dataRes.forEach(sp => {
            self.assignedSalespersonList.push({
              id: sp.id,
              text: `${sp.name}\t${sp.phone}`,
            });
          });
          self.remainingSalespersonList = self.salespersonList.filter(function(
            x
          ) {
            return self.assignedSalespersonList.indexOf(x) < 0;
          });
        })
        .catch(err => {
          console.error(err);
        });
    },
    fetchSalespersonList(dealerId) {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerInfo/dealerSaleperson?dealerId=${dealerId}`,
        {
          // params: { dealerId },
        }
      )
        .then(res => {
          self.salespersonList = res.data.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    addSalespersonRow() {
      if (!this.dealerId) {
        this.openSnackbar("Please choose a dealership!");
        return;
      }
      if (
        this.remainingSalespersonList.length == 0 ||
        this.assignedSalespersonList.length === this.salespersonList.length
      ) {
        this.openSnackbar("Max salesperson amount reached!");
        return;
      }
      this.assignedSalespersonList = [
        ...this.assignedSalespersonList,
        { ...this.remainingSalespersonList[0] },
      ];
    },
    removeSalespersonRow(targetSp) {
      this.assignedSalespersonList = this.assignedSalespersonList.filter(
        sp => sp.id !== targetSp.id
      );
    },
    assignSalesperson(newSp, index) {
      console.log("here");
      this.assignedSalespersonList = this.assignedSalespersonList.map((sp, i) =>
        i === index ? newSp : sp
      );
    },
    openSnackbar(text) {
      this.snackbarOpen = true;
      this.snackbarText = text;
    },
  },
};
</script>
