import getAxios from "services/axios-get";
import putAxios from "services/axios-put";
import createWatermark from "../../../services/create-watermark";
import uploadSignedImage from "../../../services/aws-upload-image";
import createSignedurl from "../../../services/create-singed-url";
import ImgGroupInput from "@/components/ImgGroupInput";
import SalespersonGroupInput from "@/components/SalespersonGroupInput";

export default {
  components: {
    ImgGroupInput,
    SalespersonGroupInput,
  },
  props: {
    isAd: Boolean,
  },
  data() {
    return {
      editNewCar: {
        carId: "",
        arfPrice: 0,
        carModelCode: null,
        coeCategoryId: null,
        coePrice: 0,
        coeType: null,
        color: null,
        defaultImgPath: null,
        displayImgs: [],
        draft: false,
        efficiency: 0,
        isDraft: false,
        notes: null,
        seoMetaTitle: null,
        seoMetaDescription: null,
        omvPrice: 0,
        priceHiddenFlag: 0,
        productTitle: null,
        roadTax: 0,
        salePrice: 0,
        salePricePlusCoe: 0,
        salepersonIds: [],
        vesCategoryId: null,
        videoPaths: [],
        manuYear: 0,
        sortCode: null,
        sortVariant: null,
        isCoeInclusive: null,
        pricePackage: null,
      },
      show: false,
      searchDealer: null,
      dealerEntries: [],
      displayImgsFiles: [],
      displayVideoFiles: [],
      assignedSalespersonList: [],
      displayImgsPlaceholder: null,

      radioWatermark: "none",
      dealerNameDisplay: "",
      dealerJSON: { id: null, name: null },

      carModelJSON: null,
      entries: [],
      isLoading: false,
      search: null,

      carBrandNameDisplay: "",
      carBrandJSON: null,
      carBrandEntries: [],
      carBrandIsLoading: false,
      carBrandSearch: null,

      carModelNameDisplay: "",
      carModelNameJSON: null,
      carModelNameEntries: [],

      carModelSubNameDisplay: "",
      carModelSubNameJSON: null,
      carModelSubNameEntries: [],

      carModelFinalDisplay: "",
      carModelFinalJSON: null,
      carModelFinalEntries: [],

      imgs: [],
      loadingUpd: false,
      snackbar: false,
      snackMessage: "",
      rejectedReason: null,
    };
  },
  watch: {
    // Items have already been loaded
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/carModelPair?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.entries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    searchDealer(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items

      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer`,
        {
          isAuthorized: this.isAd,
          searchContent: val,
          status: "APPROVED",
        },
        self.token
      )
        .then(res => {
          if (res.data) {
            self.dealerEntries = res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    carBrandSearch(val) {
      if (val == "") {
        this.resetCarModelSelection();
      }
      if (this.carBrandIsLoading) return;

      this.carBrandIsLoading = true;

      // Lazily load input items
      fetch(
        `https://hades.ucars.sg/relatedContent/carBrand?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          // const { count, entries } = res;
          // this.count = count;
          this.carBrandEntries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.carBrandIsLoading = false));
    },
  },
  methods: {
    showSnackBar(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },
    open(item) {
      this.rejectedReason = item.rejectedReason;
      this.editNewCar.carId = item.id;
      this.editNewCar.productTitle = item.productTitle;
      this.editNewCar.arfPrice = item.arfPrice;
      this.editNewCar.omvPrice = item.omvPrice;
      this.editNewCar.notes = item.notes;
      this.editNewCar.seoMetaTitle = item.seoMetaTitle;
      this.editNewCar.seoMetaDescription = item.seoMetaDescription;
      this.editNewCar.roadTax = item.roadTax;
      this.editNewCar.salePrice = item.salePrice;
      this.editNewCar.salePricePlusCoe = item.salePricePlusCoe;
      this.editNewCar.manuYear = item.manuYear;
      this.editNewCar.isCoeInclusive = item.isCoeInclusive;
      this.editNewCar.pricePackage = item.pricePackage;
      this.imgs = [...item.displayImgs].map(url => ({
        url,
      }));
      this.editNewCar.color = item.color;
      this.editNewCar.sortCode = item.sortCode;
      this.editNewCar.sortVariant = item.sortVariant;
      this.carModelFinalDisplay =
        item.carModelYear +
        " - " +
        item.carModelEndYear +
        " (" +
        item.carModelFaceliftedYear +
        ")";
      this.dealerNameDisplay = item.dealerName;
      this.dealerJSON = { id: item.dealerId, name: item.dealerName };
      this.carModelSubNameDisplay = item.carModelSubName;
      this.carModelNameDisplay = item.carModelName;
      this.carBrandNameDisplay = item.carBrandName;
      this.show = true;
    },
    handleCarModelSelect(carModel) {
      let self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeVesPair?carModelCode=${carModel.code}`
      )
        .then(res => res.json())
        .then(res => {
          let { name: vesCatName, value: coeCatName } = res.data;

          if (vesCatName)
            self.editNewCar.vesCategoryId = self.vesCategoryList.find(
              v => v.name === vesCatName
            ).id;

          if (coeCatName)
            self.editNewCar.coeCategoryId = self.coeCatList.find(
              c => c.name === coeCatName
            ).id;
        })
        .catch(err => console.error(err));
    },
    carBrandChange(carBrand) {
      const carBrandId = carBrand.id;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelModelName?carBrandId=${carBrandId}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelNameEntries = res.data.data;
        }
      });
    },
    carModelNameChange(carBrand, carModelName) {
      const carBrandId = carBrand.id;
      let queryParams = `carBrandId=${carBrandId}&modelName=${encodeURIComponent(
        carModelName
      )}`;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelSubModelName?${queryParams}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelSubNameEntries = res.data.data;
        }
      });
    },

    carModelSubNameChange(carBrand, carModelName, carModelSubName) {
      const carBrandId = carBrand.id;
      let queryParams = `carBrandId=${carBrandId}&modelName=${encodeURIComponent(
        carModelName
      )}&submodelName=${encodeURIComponent(carModelSubName)}`;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getCarModel?${queryParams}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelFinalEntries = res.data.data;
        }
      });
    },

    carModelFinalChange(carModelCodeSelected) {
      this.editNewCar.carModelCode = carModelCodeSelected;
    },
    resetCarModelSelection() {
      this.editNewCar.carModelCode = null;
      this.carModelNameJSON = "";
      this.carModelSubNameJSON = "";
      this.carModelFinalJSON = "";
      this.carModelNameEntries = [];
      this.carModelSubNameEntries = [];
      this.carModelFinalEntries = [];
    },
    async doEditNewCar() {
      const self = this;
      self.loadingUpd = true;
      try {
        // this.salePrice
        this.editNewCar.dealerId = this.dealerJSON ? this.dealerJSON.id : null;
        this.editNewCar.salepersonIds = this.assignedSalespersonList.map(
          m => m.id
        );
        self.editNewCar.watermarkFlag =
          this.radioWatermark == "watermark" ? 1 : 0;
        const imgResLists = self.$refs.imgs.imgs;
        let uploadUrlImageName = [];
        let img;
        for (let i = 0; i < imgResLists.length; i++) {
          img = imgResLists[i];
          if (!img.file) {
            if (
              img.url.indexOf("_wm.") === -1 &&
              self.editNewCar.watermarkFlag
            ) {
              const uploadUrl = await createSignedurl(img.url, this.token);
              if (uploadUrl === "error") {
                this.showSnackBar("Edit Car Failed");
                this.loadingUpd = false;
              }
              const watermarkedImage = await createWatermark(img.url);
              uploadUrlImageName.push(uploadUrl.data.waterMarkedName);
              const imageUpload = await uploadSignedImage(
                watermarkedImage,
                uploadUrl.data.waterMarkedUrl
              );
              if (imageUpload === "error") {
                this.showSnackBar("Edit Car Failed");
                this.loadingUpd = false;
              }
            } else {
              uploadUrlImageName.push(img.url);
            }
          } else if (img.file) {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: self.editNewCar.watermarkFlag,
              },
              this.token
            );
            if (self.editNewCar.watermarkFlag) {
              const watermarkedImage = await createWatermark(img.file);
              uploadUrlImageName.push(uploadUrl.data.waterMarkedName);
              if (watermarkedImage) {
                const imageUpload = await uploadSignedImage(
                  watermarkedImage,
                  uploadUrl.data.waterMarkedUrl
                );
                if (imageUpload === "error") {
                  this.showSnackBar("Edit Car Failed");
                  this.loadingUpd = false;
                }
              }
            } else {
              uploadUrlImageName.push(uploadUrl.data.originalName);
              const imageUpload = await uploadSignedImage(
                img.file,
                uploadUrl.data.originalUrl
              );
              if (imageUpload === "error") {
                this.showSnackBar("Edit Car Failed");
                this.loadingUpd = false;
              }
            }
          }
        }
        self.editNewCar.defaultImgPath = uploadUrlImageName.shift();
        self.editNewCar.displayImgs = uploadUrlImageName;
        self.editNewCar.watermarkFlag = 0;
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/updCarNew`,
          self.editNewCar,
          self.token
        )
          .then(res => {
            if (res.data.httpStatus === "OK") {
              self.loadingUpd =
                this.radioWatermark == "watermark"
                  ? !self.loadingUpd
                  : self.loadingUpd;
              self.show = false;
              self.$emit("edit");
            }
          })
          .catch(e => {
            self.loadingUpd = false;
            self.$emit("error", e);
          })
          .finally(() => (self.loadingUpd = false));
      } catch (e) {
        self.$emit("error", e);
        self.loadingUpd = false;
      }
    },
  },
};
